.body{
  height: 100vh;
  display: flex;
  width: auto;
 }

 .card {
  height: 35rem !important; /* Set a fixed height for the card */
  display: flex;
  
}

.card-body {
  flex: 1; /* Allow the card body to expand to fill available space */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
}

.card-title {
  flex: 0 1 auto; /* Allow title to take only necessary space */
  margin: 0; /* Remove default margin */
}

.list-group-item {
  flex: 0 1 auto; /* Allow list items to take only necessary space */
}

 .productImage {
  display: block;
  width: 100%;
  height: 100%; 
  aspect-ratio: auto 640 / 360;
  object-fit: contain;
}


@media (max-width: 667px) {
  .card-img-top {
    margin-top: 0rem;
  }
  .card-body {  
  
    justify-content: center; /* Center content vertically */
  }
}

#responsiveCart {
  width: 50%;
}


.productImage {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  aspect-ratio: auto 640 / 360;
}

.infinite-scroll-container {
  overflow-x: hidden; /* Hides horizontal scrollbar */
  overflow-y: auto;   /* Keep vertical scrolling enabled */
}
/* Media Query for Responsive Design */

@media (max-width: 667px) {
  .card {
    height: auto; /* Allow card to adjust height based on content */
    width: auto;
  }

  .card-img-top {
    margin-top: 2rem; /* Additional spacing for images on smaller screens */
  }

  .productImage {
    max-height: 13rem; /* Ensure images don’t grow too large */
   
  }
}

.list-group-item {
  flex: 0 1 auto; /* Allow list items to take only necessary space */
}

@media (max-width: 1400px) {
  #responsiveCart {
    width: 60%;
  }
  .productImage {
    width: 80%;
  }
 
}

@media (max-width: 1200px) {
  #responsiveCart {
    width: 90%;
  }
}

@media (max-width: 992px) {
  #responsiveCart {
    width: 50%;
  }
  .productImage {
    width: 70%;
  }
}

@media (max-width: 430px) {
  #responsiveCart {
    width: 60%;
  }
}
.label-style {

  padding: 0.5rem 0.75rem;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
}

.label-style.loading {
  color: #999;
  cursor: not-allowed;
  background-color: #e0e0e0;
}

.label-style:hover:not(.loading) {
  background-color: #ddd;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .label-style {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .label-style {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  
}

.productImage {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  aspect-ratio: auto 640 / 360;
}

.productImage.loaded {
  opacity: 1;
}

.custom-container{
  width: 70rem !important;
  height: 50rem !important;
}

.spinner-overlay {
  display: flex;  
  justify-content: center;
  align-items: center;
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999; /* Ensure it appears above other content */
  /* background: rgba(255, 255, 255, 0.8); */
  padding-left: 20rem;   
  border-radius: 10px; /* Optional for rounded corners */
}
