

@media (min-width: 667px) {
    .show-sorter {
      display: none;
    }
  }

  @media (max-width: 667px) {
    .hide-sorter {
      display: none;
    }
  }