.loginContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh; 
    width: 100%;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }


