
.crcsl {
    width: 27rem;
}



@media (max-width: 1200px) {
    .crcsl {
        width: 25rem;
    }
}


@media (max-width: 992px) {
    .crcsl {
        width: 17rem;
    }
}

@media (max-width: 430px) {
    .crcsl {
        width: 18rem;
    }
}

@media (max-width: 414px) {
    .crcsl {
        width: 18rem;
    }
}

@media (max-width: 390px) {
    .crcsl {
        width: 17rem;
    }
}

@media (max-width: 375px) {
    .crcsl {
        width: 16rem;
    }
}