.filter-products {
  height: 3rem;
}

.filter-sort-container {
  height: 3rem;
  font-size: 0.9rem;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: none;
}

@media (min-width: 992px) {
  .filter-products {
    display: none;
  }
  .filter-sort-container {
    display: none;
  }
}
