.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-icon {
    color: red;
    font-size: 24px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px; /* Add padding to provide space around the delete icon */
    border-radius: 10%; /* Optionally, add border-radius for a circular shape */
  }
  