
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  display: flex;
  flex: 1; 
}

.content {
  flex: 1; 
  padding: 15px; 
}


.modal-backdrop {
  display: none !important; /* Use with caution */
}
