@media (max-width: 767px) {
  #hideTotalPrice {
    display: none;
  }
}

@media (max-width: 601px) {
  #hideQty {
    display: none;
  }
}

@media (min-width: 602px) {
  #showQty {
    display: none;
  }
}

.removeItem {
  width: 3.5rem;
}

.removeItem:hover {
  cursor: pointer;
}
