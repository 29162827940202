
.spinner-container {
    display: flex; /* Use flexbox to align spinner and label */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: column; /* Stack items vertically */
    height: 100vh; /* Full viewport height */
}
.spinner {
    border: 2rem solid rgba(52, 152, 219, 0.3); /* Light blue border */
    border-radius: 50%; /* Circular shape */
    border-top: 2rem solid #3498db; /* Darker blue for the spinner color */
    width: 4rem; /* Spinner size */
    height: 4rem; /* Spinner size */
    animation: spin 1s linear infinite; /* Spinning animation */
    margin-bottom: 1rem; /* Space between spinner and label */
}

.custom-container{
  
        height: "1rem" !important;
          
}

.loading-label {
    font-size: 1.2rem; /* Increase font size */
    color: #3498db; /* Match label color with spinner color */
    text-align: center; /* Center the text */
}
/* Define the spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg); /* Start from 0 degrees */
    }
    100% {
        transform: rotate(360deg); /* Spin to 360 degrees */
    }
}






@media (max-width: 1200px) {
    .tabContainer {
        width: 90%;
    }
}

@media (min-width:1200px) {
    .tabContainer {
        width: 75%;
    }
}

