/* .logo-img {
  width: 200px; 
  position: relative;
  margin: 0;
} */


.navbar-toggler {
  display: block !important; /* Ensure the button is visible */
}

@media (min-width: 992px) {
  .navbar-toggler {
    display: none !important; /* Hide it on larger screens */
  }
}


.logo-img:hover {
  cursor: pointer;
}

.navbar {
  position: relative; /* To position the logo inside the navbar */
  height: 60px; /* Set the desired navbar height */
  padding:  1rem; /* Adjust padding as needed */
  z-index: 1;
}

.logo-img {
  height: 100px; /* The desired larger logo height */
  width: auto; /* Maintain the aspect ratio */
  position: absolute; /* Allows it to overlap the navbar */
  top: 10px; /* Adjust to move the logo above the navbar */
  left: 1rem; /* Adjust the positioning if needed */
  z-index: 5; /* Ensure the logo is above the navbar */
}
.input-group .form-control {
  display: inline-block; /* Ensures inline layout */
  width: 400px; /* Ensure it takes up space but stays inline */
}

.input-group .input-group-text {
  display: inline-block;
}


@media (max-width: 992px) {
  .navbar {
    padding: 1rem; /* Even smaller padding on smaller screens */
  }
}


@media (max-width: 992px) {
  .logo-img {
    height: 40px; /* Smaller logo for smaller screens */
  }
}


@media (max-width: 992px) {
  .logo-img {
    width: 150px;
  }
  .nav-item {
    border-bottom: solid 0.1em lightgrey;
    width: 100%;
    margin-bottom: 4px;
  }
}

@media (max-width: 992px) {
  #hideSUC {
    display: none;
  }
}

@media (min-width: 992px) {
  #hideUC {
    display: none;
  }
}
