/* Remove scrollbar from the main window */
body {
  margin: 0;
  height: 100vh;
 
}

.sidebar {   
  position: sticky; 
  top: 8rem;
  bottom: 0;
  left: 0; 
     
}
.products{
 width: auto;
 
}

/* Products container styles */
.products-container {
  flex-grow: 1;
  padding: 1.25rem;
  display: flex;
  height: 100%;
  width: 100%;
}

.load-more-btn {
  margin: 1rem;
  background: none;
  border: none;
  color: black;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

.load-more-btn:hover {
  color: #0056b3;
}

.footer {
  background-color: #a28089;
  padding: 0.5em;
  text-align: center;
  position: fixed;  /* Make it stick to the bottom */
  bottom: 0;
  left: 0;          /* Ensure it starts at the left edge */
  width: 100%;      /* Take up the full width */
  color: white;     /* Optional: Text color */
}

.App {
  padding-bottom: 4em; /* Adjust this depending on your footer height */
}
.footer-logo .logo {
  height: 25px; /* Adjust as needed for logo height */
}



/* Media query for larger screens (992px and up) */
@media (min-width: 992px) {
  .filterAndSort {
    display: none;
  }

  .products-container {
    max-height: calc(100vh - 40px);
    padding: 40px;
  }
}

/* Media query for medium screens (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .products-container {
    max-height: calc(100vh);
    padding: 0;
    width: 100%;
  }
}

/* Media query for small screens (less than 768px) */
@media (max-width: 767px) {
  .parent-container {
    flex-direction: column;
  }

  .products-container {
    max-height: calc(100vh - 60px);
    padding: 10px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden !important;
  }

  .load-more-btn {
    font-size: 14px;
  }
}
