@media (max-width: 767px) {
  #columnOneButton {
    display: none;
  }
}

@media (min-width: 768px) {
  #columnTwoButton {
    display: none;
  }
}
