@media (min-width: 991.98px) {
  main {
    padding-left: 240px; /* Ensures main content moves when sidebar is visible */
  }
}

.sidebar {
  position: fixed; /* Makes the sidebar fixed for scrolling */
  top: 8rem;
  bottom: 0;
  left: 0;
  width: 16rem; /* Sidebar width on large screens */
  z-index: 600;
  overflow-y: auto; /* Ensures scroll when content overflows */
  max-height: calc(100vh - 8rem); /* Set the maximum height relative to the viewport */
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
}

.sidebar::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome, Safari, and Opera */
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%; /* Sidebar takes full width on smaller screens */
    top: 0; /* Adjust for mobile view */
    max-height: 100vh; /* Allow the sidebar to occupy the full screen height */
  }
}

.sidebar-sticky {
  position: sticky;
  top: 0;
}
